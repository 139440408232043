import { getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { heading2Style } from '../utils/FontAndSpacingStyles'
import { useIntl } from '../utils/IntlContext'
import media from '../utils/media'
import BlogListingItem from './BlogListingItem'
import Section from './Section'
import Wrapper from './Wrapper'

const RelatedBlogContainer = styled(Section)`
  padding: 32px 24px;
  background-color: var(--color-background-bluish-purple);

  ${media.md`
    padding: 64px 20px;
  `}
`

const RelatedBlogHeading = styled.span`
  color: var(--color-typography-dark);
  display: block;
  ${heading2Style}

  margin-bottom: 24px;

  ${media.md`
    margin-bottom: 32px;
  `}
`

const RelatedPosts = styled.div`
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const BlogPostFeaturedPosts = ({ parentBasePath, relatedPosts }) => {
  const { t } = useIntl()

  const translatedRelatedPosts = relatedPosts?.filter((post) => post.slug && post.title)

  if (!translatedRelatedPosts || translatedRelatedPosts.length === 0) {
    return null
  }

  return (
    <RelatedBlogContainer>
      <Wrapper>
        <RelatedBlogHeading>{t('more_from_the_blog')}</RelatedBlogHeading>
        <RelatedPosts>
          {translatedRelatedPosts.map((post) => (
            <BlogListingItem
              key={post.slug}
              parentBasePath={parentBasePath}
              featuredImg={getImage(post.featured_image.localFile)}
              slug={post.slug}
              categories={post.blog_category?.slice(0, 2)}
              authorName={post.author.name}
              authorTitle={post.author.title}
              authorAvatar={getImage(post.author.photo.localFile)}
              date={post.date}
              title={post.title}
              description={post.description?.description}
              useTitleAsLink
            />
          ))}
        </RelatedPosts>
      </Wrapper>
    </RelatedBlogContainer>
  )
}

export default BlogPostFeaturedPosts
