import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import { useIntl } from '../utils/IntlContext'
import { slugify, urlPathJoin } from '../utils/locales'
import media from '../utils/media'
import Link from './Link'
import MarkdownContent from './MarkdownContent'

const AuthorCardWrapper = styled.div`
  padding: 24px;

  ${media.md`
    max-width: 1112px;
    margin: auto;
    padding: 24px 0;
  `}
`

const AuthorCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 800px;
  padding: 24px;
  border-radius: var(--border-radius-large);
  background-color: var(--color-background-powder-blue);
`

const Avatar = styled(GatsbyImage)`
  border-radius: 50%;
  margin: auto 24px auto 0;
  max-height: 65px;
  max-width: 65px;
`

const AuthorSnapshot = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 24px;
  flex-direction: column;

  ${media.md`
    flex-direction: row;
  `}
`

const AuthorTitle = styled.p`
  font-weight: 600;
  line-height: 18px;
  color: var(--color-typography-dark);
  margin-bottom: 8px;
`

const AuthorSubtitle = styled.p`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: ${({ $writtenBy }) => ($writtenBy ? '8px' : 'unset')};
`

const AuthorSummary = styled.div`
  max-height: 65px;
  margin-top: 8px;

  ${media.md`
    margin-top: unset;
  `}
`

const Divider = styled.div`
  display: block;
  margin-bottom: 24px;
  border-top: 1px solid var(--color-primary);
`

const additionalStyles = css`
  padding: unset;
  width: 100%;

  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }
`

const AuthorLink = styled(Link)`
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-primary);
`

const BlogAuthorCard = ({ author, parentBasePath }) => {
  const { t } = useIntl()

  if (!author) {
    return null
  }

  const {
    name,
    bio: {
      childMarkdownRemark: { htmlAst, images },
    },
    photoLarge,
    title,
  } = author

  return (
    <AuthorCardWrapper>
      <Divider />
      <AuthorCard>
        <AuthorSnapshot>
          <Avatar
            image={getImage(photoLarge.localFile)}
            alt={name}
          />
          <AuthorSummary>
            <AuthorSubtitle $writtenBy>Written By</AuthorSubtitle>
            <AuthorTitle>{name}</AuthorTitle>
            <AuthorSubtitle>{title}</AuthorSubtitle>
          </AuthorSummary>
        </AuthorSnapshot>
        <MarkdownContent
          el={htmlAst}
          images={images}
          additionalStyles={additionalStyles}
        />
        {parentBasePath && (
          <AuthorLink to={urlPathJoin(parentBasePath, slugify(name))}>{t('see_more_author', { name })}</AuthorLink>
        )}
      </AuthorCard>
    </AuthorCardWrapper>
  )
}

export default BlogAuthorCard
