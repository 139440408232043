import { navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { useIntl } from '../utils/IntlContext'
import { formatISODate, slugify, urlPathJoin } from '../utils/locales'
import { legacyBreakpoints } from '../utils/media'
import BlogListingAuthorInfo from './BlogListingAuthorInfo'
import HoverCard from './HoverCard'
import Link from './Link'

const PostBgImage = styled(GatsbyImage)`
  && {
    transition: all 250ms cubic-bezier(0.4, 0, 0.25, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
`

const PostImageParent = styled.div`
  position: relative;
  transition: all 250ms cubic-bezier(0.4, 0, 0.25, 1);
  height: 168px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 335px;
  justify-content: space-between;
  padding: 24px;
`

const StyledLink = styled(Link)`
  display: block;
  flex-shrink: 0;
`

const BlogPostTitle = styled.h2`
  color: var(--color-typography-dark);
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;
`

const BlogTag = styled.span`
  display: block;
  padding: 4px 16px;
  font-size: 12px;
  font-weight: bold;
  width: fit-content;
  border-radius: 12px;
  margin-bottom: 4px;
  margin-right: 5px;
  background: var(--color-background-blog-light-blue);
  min-width: fit-content;
  white-space: nowrap;
  overflow: hidden;

  && {
    color: var(--color-primary);
  }
`

const CategoriesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const BlogPostDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  overflow: hidden;

  && {
    color: var(--color-grey);
  }
`

const BlogPostDate = styled.p`
  flex-shrink: 0;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 8px;

  && {
    color: var(--color-grey);
  }
`

const BlogPostReadPost = styled.p`
  flex-shrink: 0;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;

  && {
    color: var(--color-primary);
  }
`

const BlogListingPostInfo = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const PostWrapper = styled.div`
  position: relative;
  margin: 0;
  background-color: white;
  border-radius: 10px;
  height: 503px;
  overflow: hidden;
  box-shadow: var(--box-shadow-primary-small);
  transition: all 250ms cubic-bezier(0.4, 0, 0.25, 1);
  isolation: isolate;

  flex: 0 0 calc(33.33% - (2 * var(--spacing-xsm) / 3));

  @media screen and (max-width: ${legacyBreakpoints.tablet}) {
    flex: 0 0 calc(50% - (var(--spacing-xsm) / 2));
  }

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    flex: 0 0 100%;
  }

  &:hover {
    box-shadow: var(--box-shadow-primary-medium-bottom-with-glow);
    transform: translateY(-4px);
    ${HoverCard} {
      transform: translateY(0);
    }

    ${PostImageParent} {
      background-color: var(--color-background-black);
    }

    ${PostBgImage} {
      filter: opacity(0.85);
    }
  }
`

const BlogListingItem = ({
  parentBasePath,
  featuredImg,
  slug,
  authorName,
  authorTitle,
  authorAvatar,
  date,
  title,
  description,
  timeToRead,
  imageLoading,
  categories,
  useTitleAsLink,
}) => {
  const { locale, t } = useIntl()

  return (
    <PostWrapper>
      <HoverCard as="div">
        <PostImageParent onClick={() => navigate(urlPathJoin(parentBasePath, slug))}>
          <PostBgImage
            image={featuredImg}
            alt={title}
            loading={imageLoading}
          />
        </PostImageParent>
        <Content>
          <BlogListingPostInfo onClick={() => navigate(urlPathJoin(parentBasePath, slug))}>
            <CategoriesWrapper>
              {categories?.map((category, index) => (
                <BlogTag key={`category${index}`}>{category.title}</BlogTag>
              ))}
            </CategoriesWrapper>
            <StyledLink to={urlPathJoin(parentBasePath, slug)}>
              <BlogPostTitle as={useTitleAsLink ? 'span' : 'h2'}>{title}</BlogPostTitle>
            </StyledLink>
            {description && <BlogPostDescription>{description}</BlogPostDescription>}
            <BlogPostDate dir={locale === 'ar' ? 'rtl' : undefined}>
              {formatISODate(date, locale)} {timeToRead && `- ${t('time_to_read', { minutes: timeToRead })}`}
            </BlogPostDate>
            <BlogPostReadPost>Read Post</BlogPostReadPost>
          </BlogListingPostInfo>
          <BlogListingAuthorInfo
            avatar={authorAvatar}
            name={authorName}
            title={authorTitle}
            uri={urlPathJoin(parentBasePath, slugify(authorName))}
            loading="lazy"
          />
        </Content>
      </HoverCard>
    </PostWrapper>
  )
}

export default BlogListingItem
