import styled, { css } from 'styled-components'
import { useIntl } from '../utils/IntlContext'
import media from '../utils/media'
import MarkdownContent from './MarkdownContent'
import TableOfContents from './TableOfContents'

const MainContentWrapper = styled.div`
  padding: 24px;

  ${media.md`
    width: fit-content;
    margin: auto;
  `}
`

const MainContent = styled.div`
  display: flex;
  flex-direction: column-reverse;
  max-width: 680px;
  position: relative;
  margin: auto;

  ${media.lg`
    display: block;

    ${({ $tableOfContents }) =>
      $tableOfContents
        ? css`
            max-width: 1150px;
            padding-right: 20vw;
          `
        : css`
            max-width: 680px;
          `}
  `}
`

const additionalStyles = css`
  padding: 24px 24px 0 0;

  & > p:first-of-type::first-letter {
    margin-right: 10px;
    color: var(--color-typography-dark);
    font-size: 30px;
    line-height: 40px;
    font-size: 72px;
  }

  h2[id]:before {
    display: block;
    visibility: hidden;
    content: '';
    padding-bottom: var(--nav-header-height);
    margin-top: calc(-1 * var(--nav-header-height));
    width: 0;
  }

  h2[id] a {
    margin-top: var(--nav-header-height);
  }

  p {
    z-index: 1;
    position: relative;
  }

  iframe {
    margin-bottom: 40px;
    max-width: 100%;
  }

  ${media.lg`
    padding: 0 24px 0 0;
  `}
`

const StickyWrapper = styled.div`
  ${media.lg`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 20vw;
    margin: unset;
  `}
`

const Content = ({ tableOfContents, htmlAst, images }) => {
  const { t } = useIntl()
  return (
    <MainContentWrapper>
      <MainContent $tableOfContents={tableOfContents}>
        <MarkdownContent
          el={htmlAst}
          images={images}
          additionalStyles={additionalStyles}
        />
        {tableOfContents && (
          <StickyWrapper>
            <TableOfContents
              tableOfContents={tableOfContents}
              title={t('table_of_contents')}
            />
          </StickyWrapper>
        )}
      </MainContent>
    </MainContentWrapper>
  )
}

export default Content
