import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { useIntl } from '../utils/IntlContext'
import { getLocalePathPrefix, slugify, urlPathJoin } from '../utils/locales'
import media from '../utils/media'
import ConditionalWrapper from './ConditionalWrapper'
import Link from './Link'
import Wrapper from './Wrapper'

const BannerWrapper = styled.div`
  background-color: var(--color-background-mint);
  padding: calc(var(--nav-header-height) + 35px) 25px 32px;

  ${media.md`
    padding-top: calc(var(--nav-header-height) + 35px);
    padding-bottom: 40px;
  `}
`

const PostImage = styled(GatsbyImage)`
  background-size: cover;
  background-position: center;
  border-radius: var(--border-radius-large);
`

const PostContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 25px 0 0 0;

  ${media.md`
    width: 50%;
    padding-right: 25px;
  `}
`

const HeaderContainer = styled.div`
  align-items: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;

  ${media.md`
    flex-direction: row-reverse;
    padding-top: 45px;
  `}
`

const Avatar = styled(GatsbyImage)`
  border-radius: 50%;
  display: inline-block;
  margin-right: 15px;
  max-width: 48px;
  max-height: 48px;

  ${media.md`
    max-width: 72px;
    max-height: 72px;
  `}
`

const PostByline = styled.div`
  display: flex;
  align-items: center;
`

const PostBylineText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 5px 0;
`

const AuthorName = styled.span`
  font-size: 16px;
  line-height: 24px;
  transition: all 0.5s;
`

const PublishedOnDate = styled.span`
  color: var(--color-typography-medium);
  font-size: 14px;
  line-height: 20px;
`

const BlogLink = styled(Link)`
  border-bottom: 2px solid var(--color-primary);
  padding-bottom: 2px;

  ${media.md`
    border-bottom: 3px solid var(--color-primary);
  `}
`

const CategoryLink = styled(Link)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
  color: var(--color-primary);

  ${media.sm`
    overflow: visible;
  `}
`

const Divider = styled.div`
  &:after {
    content: '/';
    padding: 0 5px;
  }
`

const BlogLinkWrapper = styled.div`
  display: flex;
`

const BlogTitle = styled.h1`
  font-size: 32px;
  line-height: 32px;
  letter-spacing: 0.7px;
  margin-bottom: 25px;

  ${media.md`
    font-size: 48px;
    line-height: 48px;
  `}
`

const StyledLink = styled(Link)`
  &:hover {
    ${AuthorName} {
      color: var(--color-primary);
    }
  }
`

const Banner = ({
  parentBasePath,
  featuredImage,
  avatar,
  title,
  name,
  date,
  contentPage,
  timeToRead,
  authorTitle,
  currentCategory,
}) => {
  const { locale, t } = useIntl()

  return (
    <BannerWrapper>
      <Wrapper>
        {!contentPage && (
          <BlogLinkWrapper>
            <BlogLink to={getLocalePathPrefix(locale)}>{t('homepage')}</BlogLink>
            <Divider />
            <BlogLink to={parentBasePath}>{t('blog')}</BlogLink>
            {currentCategory && (
              <>
                <Divider />
                <CategoryLink to={urlPathJoin(parentBasePath, currentCategory.slug)}>
                  {currentCategory.title}
                </CategoryLink>
              </>
            )}
          </BlogLinkWrapper>
        )}
        <HeaderContainer>
          <PostImage
            image={featuredImage}
            alt={title}
            loading="eager"
          />
          <PostContent>
            <BlogTitle>{title}</BlogTitle>
            <ConditionalWrapper
              condition={parentBasePath}
              wrapper={(children) => (
                <StyledLink to={urlPathJoin(parentBasePath, slugify(name))}>{children}</StyledLink>
              )}>
              <PostByline>
                {avatar && (
                  <Avatar
                    image={avatar}
                    alt={name}
                    loading="eager"
                  />
                )}
                <PostBylineText>
                  <AuthorName>{name}</AuthorName>
                  <PublishedOnDate>{authorTitle}</PublishedOnDate>
                  {date && (
                    <PublishedOnDate>
                      {t('updated')} {date} {timeToRead && `- ${t('time_to_read', { minutes: timeToRead })}`}
                    </PublishedOnDate>
                  )}
                </PostBylineText>
              </PostByline>
            </ConditionalWrapper>
          </PostContent>
        </HeaderContainer>
      </Wrapper>
    </BannerWrapper>
  )
}

export default Banner
