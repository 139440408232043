import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import Link from './Link'

const AuthorName = styled(Link)`
  color: var(--color-primary) !important;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
`

const AuthorTitle = styled.p`
  color: var(--color-light-grey) !important;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const AvatarMask = styled(GatsbyImage)`
  border-radius: ${({ image }) => image.width}px;
  isolation: isolate;
  margin-right: 10px;
  min-width: ${({ image }) => image.width}px;
`

const AuthorContainer = styled.div`
  display: flex;
`

const AuthorNameTitleContainer = styled.div`
  overflow: hidden;
`

const BlogListingAuthorInfo = ({ name, title, avatar, loading, uri }) => (
  <AuthorContainer>
    {avatar && (
      <AvatarMask
        image={avatar}
        alt={name}
        loading={loading}
      />
    )}
    {name && (
      <AuthorNameTitleContainer>
        <AuthorName to={uri}>{name}</AuthorName>
        {title && <AuthorTitle>{title}</AuthorTitle>}
      </AuthorNameTitleContainer>
    )}
  </AuthorContainer>
)

export default BlogListingAuthorInfo
