import { graphql } from 'gatsby'
import { getImage, getSrc } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import BlogAuthorCard from '../components/BlogAuthorCard'
import BlogBanner from '../components/BlogBanner'
import BlogContent from '../components/BlogContent'
import BlogPostFeaturedPosts from '../components/BlogPostFeaturedPosts'
import GatsbyHead from '../components/GatsbyHead'
import LayoutWrapper from '../components/LayoutWrapper'
import PageBannerLogos from '../components/PageBannerLogos'
import { useIntl } from '../utils/IntlContext'
import { getLocalizedRootUrlPrefix, slugify, urlPathJoin } from '../utils/locales'

export const query = graphql`
  query BlogPost($contentful_id: String!, $locale: String!) {
    contentfulBlogPost(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      id
      contentful_id
      parentPage {
        slug
      }
      slug
      node_locale
      date
      formattedDate: date(formatString: "L", locale: $locale)
      title
      featured_image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 718)
          }
        }
      }
      content {
        childMarkdownRemark {
          htmlAst
          timeToRead
          tableOfContents(maxDepth: 2)
          images {
            contentful_id
            title
            localFile {
              childImageSharp {
                gatsbyImageData(width: 760)
              }
              publicURL
              extension
            }
          }
        }
      }
      description {
        description
      }
      author {
        id
        name
        twitter
        title
        photo {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 72, layout: FIXED)
            }
          }
        }
        photoLarge: photo {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 100, layout: FIXED)
            }
          }
        }
        bio {
          childMarkdownRemark {
            htmlAst
            images {
              contentful_id
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 760)
                }
                publicURL
                extension
              }
            }
          }
        }
      }
      related_posts {
        title
        slug
        blog_category {
          title: categoryTitle
        }
        featured_image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 354)
            }
          }
        }
        description {
          id
          description
        }
        date
        author {
          name
          title
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 40, layout: FIXED)
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const FeaturedTitle = styled.span`
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  display: block;
  margin: 0 0 20px 0;
  padding-top: 20px;
  color: var(--color-typography-medium);
`

const BlogPost = ({
  pageContext: { translatedPages },
  data: {
    contentfulBlogPost: {
      parentPage,
      title,
      description,
      formattedDate,
      content,
      author,
      featured_image,
      related_posts,
    },
  },
  location,
}) => {
  const { locale, t } = useIntl()
  const parentBasePath = getLocalizedRootUrlPrefix(parentPage, locale)
  const currentCategory = location.state?.currentCategory
  const PageBannerStyles = css`
    div:only-child {
      padding: 0 20px 0;
    }
  `

  return (
    <LayoutWrapper translatedPages={translatedPages}>
      <BlogBanner
        parentBasePath={parentBasePath}
        currentCategory={currentCategory}
        featuredImage={getImage(featured_image.localFile)}
        title={title}
        description={description?.description}
        date={formattedDate}
        timeToRead={content?.childMarkdownRemark.timeToRead}
        name={author.name}
        authorTitle={author.title}
        avatar={getImage(author.photo.localFile)}
      />
      <FeaturedTitle>{t('featured_in')}</FeaturedTitle>
      <PageBannerLogos css={PageBannerStyles} />
      <BlogContent
        htmlAst={content?.childMarkdownRemark.htmlAst}
        images={content?.childMarkdownRemark.images}
        tableOfContents={content?.childMarkdownRemark.tableOfContents}
      />
      <BlogAuthorCard
        author={author}
        parentBasePath={parentBasePath}
      />
      <BlogPostFeaturedPosts
        parentBasePath={parentBasePath}
        relatedPosts={related_posts}
      />
    </LayoutWrapper>
  )
}

/** @type {import('gatsby').HeadFC} */
export const Head = ({
  pageContext: {
    canonicalUrl,
    intl: { locale },
    translatedPages,
  },
  data: {
    contentfulBlogPost: { parentPage, title, description, date, author, featured_image },
    site: {
      siteMetadata: { siteUrl },
    },
  },
}) => {
  const parentBasePath = getLocalizedRootUrlPrefix(parentPage, locale)

  return (
    <GatsbyHead
      pageTitle={title}
      metaDescription={description?.description}
      ldJson={{
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': canonicalUrl,
        },
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: featured_image && `${siteUrl}${getSrc(featured_image.localFile)}`,
        },
        datePublished: date,
        author: {
          '@type': 'Person',
          name: author.name,
          url: `${siteUrl}${urlPathJoin(parentBasePath, slugify(author.name))}`,
        },
        publisher: {
          '@type': 'Organization',
          name: 'VisualCV',
          logo: {
            '@type': 'ImageObject',
            url: `${siteUrl}/assets/images/vcv_blue.png`,
          },
        },
        description: description?.description,
      }}
      canonicalUrl={canonicalUrl}
      translatedPages={translatedPages}
      image={getSrc(featured_image.localFile)}
      author={author.name}
    />
  )
}

export default BlogPost
