import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import media from '../utils/media'
import Chevron from '../assets/svg/chevron-down.svg'

const TableOfContentsHTML = styled.div`
  transition: max-height 0.5s;
  max-height: 700px;
  max-height: 700px;
  overflow-y: auto;
  scroll-behavior: smooth;

  li {
    margin-bottom: 16px;
  }

  li::marker {
    color: var(--color-bullet);
  }

  a {
    color: var(--color-primary);
  }

  a[href='#extra-tips'] {
    color: transparent;
  }

  color: transparent;
  a[href='#extra-tips']:before {
    content: 'Extra tips';
    width: fit-content;
    color: var(--color-primary);
  }

  ${media.lg`
    max-height: calc((100vh - 175px) - var(--nav-header-height));
  `}
`

const TableOfContentsTitle = styled.span`
  color: var(--color-typography-dark);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
  letter-spacing: -0.5px;
  display: block;
  margin-bottom: 16px;
  transition: margin-bottom 0.75s;
`

const TableOfContentsCheckbox = styled.input`
  width: 24px;
  height: 24px;
  z-index: 2;
  position: absolute;
  right: 24px;
  opacity: 0;
  cursor: pointer;

  ${media.lg`
    display: none;
  `}
`

const StyledChevron = styled(Chevron)`
  z-index: 1;
  position: absolute;
  right: 24px;
  transform: rotate(180deg);
  transition: transform 0.5s;
  stroke: var(--color-primary);

  ${media.lg`
    display: none;
  `}
`

const TOCScroll = styled(Chevron)`
  margin: auto;
  transition: all 0.5s;
  stroke: var(--color-primary);
  display: none;

  ${media.lg`
    display: block;
  `}
`

const TOCScrollWrapper = styled.div`
  display: block;
  height: 24px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  cursor: pointer;
  border-bottom-left-radius: var(--border-radius-large);
  border-bottom-right-radius: var(--border-radius-large);
  transition: 0.3s;
  pointer-events: ${({ $displayScrollButton }) => ($displayScrollButton ? 'auto' : 'none')};
  opacity: ${({ $displayScrollButton }) => ($displayScrollButton ? '1' : '0')};

  &:hover {
    background: #00000010;
  }
`

const TableOfContentsWrapper = styled.div`
  border-radius: var(--border-radius-large);
  background-color: var(--color-background-light-2);
  padding: 24px;
  position: relative;
  margin: auto;

  input${TableOfContentsCheckbox}:checked ~ div${TableOfContentsHTML} {
    max-height: 0;
  }

  input${TableOfContentsCheckbox}:checked ~ span${TableOfContentsTitle} {
    margin-bottom: 0;
  }

  input${TableOfContentsCheckbox}:checked ~ svg${StyledChevron} {
    transform: rotate(0deg);
  }

  ${media.lg`
    margin-right: var(--spacing-sm);
    position: sticky;
    top: calc(var(--spacing-sm) + var(--nav-header-height));
    max-width: 300px;

    input${TableOfContentsCheckbox}:checked ~ div${TableOfContentsHTML} {
      max-height: calc((100vh - 132px) - var(--nav-header-height));
    }

    input${TableOfContentsCheckbox}:checked ~ span${TableOfContentsTitle} {
      margin-bottom: 16px;
    }
  `}
`

const TableOfContents = ({ tableOfContents, className, title, children }) => {
  const [displayScrollButton, setDisplayScrollButton] = useState(true)
  const TOCRef = useRef(null)

  const clickScroll = () => {
    TOCRef.current.scrollTo(0, TOCRef.current.scrollHeight)
  }

  const handleTOCScroll = () => {
    const scrollPosition = TOCRef.current.scrollTop + TOCRef.current.clientHeight
    const scrollHeight = TOCRef.current.scrollHeight - 20

    if (scrollPosition >= scrollHeight) {
      setDisplayScrollButton(false)
    }

    if (scrollPosition <= scrollHeight && !displayScrollButton) {
      setDisplayScrollButton(true)
    }
  }

  useEffect(() => {
    if (TOCRef.current?.clientHeight === TOCRef.current?.scrollHeight) {
      setDisplayScrollButton(false)
    }
  }, [])

  return (
    <TableOfContentsWrapper className={className}>
      <TableOfContentsCheckbox
        type="checkbox"
        defaultChecked
      />
      <StyledChevron />
      <TableOfContentsTitle>{title}</TableOfContentsTitle>
      <TableOfContentsHTML
        ref={TOCRef}
        onScroll={() => handleTOCScroll()}
        {...(tableOfContents ? { dangerouslySetInnerHTML: { __html: tableOfContents } } : { children })}
      />
      <TOCScrollWrapper
        $displayScrollButton={displayScrollButton}
        onClick={() => clickScroll()}>
        <TOCScroll />
      </TOCScrollWrapper>
    </TableOfContentsWrapper>
  )
}

export default TableOfContents
